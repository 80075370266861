<template>
    
    <BaseSimWidget id="controlWidget">

        <template v-slot:title>
            <i class="fas fa-play"></i>
            {{ exercise.name }}
        </template>

        <b-tabs align="right" pills v-model="tabIndex">

            <b-tab>
                <template #title>
                    <i class="fas fa-play"></i>
                </template>

                <br><br>
                <b-container fluid>
                    <b-row>
                        <b-col cols="auto">
                            <h3>
                                <!-- {{ exercise.name }} -->
                            </h3>
                        </b-col>
                        <b-col cols="auto">
                            <!-- <ExerciseStatus :status="exercise.status" /> -->
                        </b-col>
                        <b-col class="text-right" v-if="isExerciseController() && !exercise.template">
                            <i class="fas fa-stop fa-2x tw-cursor-pointer tw-mr-2" @click="stop()" v-if="exercise.status == 'playing' || exercise.status == 'paused'" title="Stop Exercise"></i>
                            <i class="fas fa-play fa-2x tw-cursor-pointer" @click="play()" v-if="['open', 'paused'].indexOf(exercise.status) >= 0" title="Play Exercise"></i>
                            <i class="fas fa-pause fa-2x tw-cursor-pointer" @click="pause()" v-if="exercise.status == 'playing'" title="Pause Exercise"></i>

                            <i class="fas fa-fast-backward fa-2x tw-cursor-pointer tw-mr-2" @click="resetCurrentExerciseTime()" v-if="['stopped', 'archived', 'replay'].indexOf(exercise.status) >= 0" title="Reset Exercise to beginning."></i>
                            <i class="fas fa-play fa-2x tw-cursor-pointer" @click="rePlay()" v-if="['stopped', 'archived'].indexOf(exercise.status) >= 0" title="Replay Exercise"></i>
                            <i class="fas fa-pause fa-2x tw-cursor-pointer" @click="rePause()" v-if="exercise.status == 'replay'" title="Pause Exercise"></i>
                        </b-col>
                    </b-row>
                    <b-row  v-if="isExerciseController() && ['stopped'].indexOf(exercise.status) >= 0">
                        <b-col></b-col>
                        <b-col cols="auto">
                            <b-form-input
                                id="currentTime"
                                v-model="hiddenCurrentTime"
                                required
                                :placeholder="secondsToFormattedSendTime(currentTime)"
                                @keydown.enter="jumpTo()"
                            ></b-form-input>
                        </b-col>
                    </b-row>

                    <b-row class="mt-5" v-if="isDebugVisible() || !exercise.template">
                        <b-col class="text-left">
                            <h5><SimDate :input-date="exercise.startSimDate" :current-time="exercise.currentTime" /></h5>
                        </b-col>
                        <b-col class="text-right">
                            <h5><SimTime :input-date="exercise.startSimDate" :current-time="exercise.currentTime" /></h5>
                        </b-col>
                    </b-row>

                    <b-row v-if="isDebugVisible() || !exercise.template">
                        <b-col>
                            <ExerciseProgress :exercise="exercise" :current-time="exercise.currentTime" />
                        </b-col>
                    </b-row>

                    <b-row v-if="isDebugVisible() || !exercise.template">
                        <b-col>
                            <!-- {{exercise.currentTime}} -->
                            <h3>
                                <b-badge class="float-right">
                                <i class="fas fa-clock"></i>
                                {{ exercise.currentTime }}
                            </b-badge>

                        <b-badge class="float-right mr-2">
                                <i class="fas fa-user-clock"></i>
                                {{ secondsToFormattedSendTime(exercise.currentTime) }}
                            </b-badge>
                            </h3>
                        </b-col>
                    </b-row>

                    <b-row v-if="isDebugVisible() || !exercise.template">
                        <b-col class="text-center">
                            <hr>
                        </b-col>
                    </b-row>

                    <b-row class="mt-3" v-if="isDebugVisible() || !exercise.template">
                        <b-col class="text-left">
                            <i class="fas fa-backward fa-2x tw-cursor-pointer" @click="decrease()" v-if="isExerciseController()"></i>
                        </b-col>
                        <b-col class="text-center">
                            <!-- <i class="fas fa-bars fa-2x"></i> -->
                            <h3>
                                <b-badge>{{ exercise.speed ? exercise.speed.toFixed(1) : 1 }}x</b-badge>
                            </h3>
                        </b-col>
                        <b-col class="text-right">
                            <i class="fas fa-forward fa-2x tw-cursor-pointer" @click="increase()" v-if="isExerciseController()"></i>
                        </b-col>
                    </b-row>

                    

                    <!-- <b-row class="mt-3">
                        <b-col class="text-left">
                            
                        </b-col>
                        <b-col class="text-center">
                            <h3>
                                <b-badge>{{ exercise.currentTime }}</b-badge>
                            </h3>
                        </b-col>
                        <b-col class="text-right">
                            
                        </b-col>
                    </b-row> -->

                    <b-row class="mt-5 tw-border-2 tw-border-radius">
                        <b-col>
                            <div class="tw-my-7" v-html="exercise.description"></div>
                        </b-col>
                    </b-row>
                </b-container>
            </b-tab>

            <b-tab v-if="isExerciseController()">
                <template #title>
                    <i class="fas fa-pen"></i>
                </template>

                <!-- <ExerciseForm :exerciseId="exercise._id" :redirect="false" /> -->
                <ExerciseForm
                    :exercise="exercise"
                    :redirect="false"
                    v-on:exerciseSaved="exerciseSaved($event)"
                />

            </b-tab>

        </b-tabs>
    </BaseSimWidget>
</template>

<script>
import {
    HTTP
} from '@/util/http-common';
// import HandleErrors from '@/util/handle-errors';

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import { mapState } from "vuex";

import socketIOMixin from "@/util/socketio";
import ExerciseForm from '../../../views/SimEOC/exercise/ExerciseForm.vue';

export default {
    name: "ControlWidget",
    mixins: [WidgetMixin, socketIOMixin],
    props: {
        exercise: {
            type: Object,
            required: true,
            default: Object,
        },
        fullScreen: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        return {
            show: true,
            fullWidth: false,
            title: "Sample Page",
            socketName: "",
            exerciseInterval: null,
            currentTime: 0,
            hiddenCurrentTime: "",
            tabIndex: 0,
        };
    },
    computed: {
        ...mapState("sample", ["sample"]),
    },
    created: function () {
    },
    mounted: function () { },
    methods: {
        play: function () {
            const that = this;
            if (that.exercise.status == 'open') {
                if(!that.exercise.startSimDate) {
                    that.$bvModal.msgBoxConfirm(`Exercise start time must be set before playing the exercise!`);
                    return;
                }

                that.$bvModal.msgBoxConfirm(`Are you sure you want to play the exercise? Operation cannot be undone!`)
                    .then(async (val) => {
                    if (val) {
                        that.$http.patch(`/exercise/${that.exercise._id}`, { status: 'playing' });
                    }
                });
            } else {
                that.$http.patch(`/exercise/${that.exercise._id}`, { status: 'playing' });
            }
        },
        pause: function () {
            // this.emit('pause', this.exercise._id);
            const that = this;
            that.$http.patch(`/exercise/${that.exercise._id}`, { status: 'paused' });
        },
        stop: function () {
            const that = this;
            that.$bvModal.msgBoxConfirm(`Are you sure you want to stop the exercise? Operation cannot be undone!`)
                .then(async (val) => {
                if (val) {
                    that.$http.patch(`/exercise/${that.exercise._id}`, { status: 'stopped' });
                }
            });
        },
        increase: function () {
            // this.emit('increase', this.exercise._id);
            const that = this;
            that.$http.patch(`/exercise/${that.exercise._id}`, { 'speed': that.exercise.speed + 1 });
        },
        decrease: function () {
            // this.emit('decrease', this.exercise._id);
            const that = this;
            that.$http.patch(`/exercise/${that.exercise._id}`, { 'speed': that.exercise.speed - 1 });
        },
        rePlay: function () {
            const that = this;
            that.exerciseInterval = setInterval(() => {
                that.currentTime += 1;
                that.$emit('replayupdateexerciseinfo', { currentTime: that.currentTime, status: 'replay' });
            }, 1000);
        },
        rePause: function () {
            this.$emit('replayupdateexerciseinfo', { status: 'stopped' });
            clearInterval(this.exerciseInterval);
            this.exerciseInterval = null;
        },
        jumpTo() {
            this.exercise.currentTime = this.currentTime = parseInt(this.hiddenCurrentTime);
            this.$emit('replayupdateexerciseinfo', { currentTime: this.exercise.currentTime });
            this.hiddenCurrentTime = "";
        },
        setCurrentExerciseTimeIncrement(x) {
            this.exercise.currentTime += x;
            this.$store.commit('sim/setExerciseCurrentTime', this.exercise.currentTime);
            this.currentTime = this.exercise.currentTime;
        },
        resetCurrentExerciseTime() {
            this.currentTime = 0;
            this.$emit('replayupdateexerciseinfo', { currentTime: this.currentTime });
        },
        exerciseSaved(exercise) {
            // Switch to main tab.
            console.log("exerciseSaved", exercise);
            this.tabIndex = 0;
        }
    },
    components: { ExerciseForm }
};
</script>
