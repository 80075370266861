<template>
  <b-container fluid>
    <b-row>
      <b-col cols="2">

        <b-list-group>
          <b-list-group-item
            class="tw-cursor-pointer"
            :variant="chat && chat._id == item._id ? 'primary' : ''"
            v-for="(item, idx) in itemList"
            :key="item._id"
            @click="chat = item"
          >
            <b-avatar
              square
              :badge="String(item.chatCounter)"
              badge-variant="light"
            >
              <i class="fas fa-2x" :class="getClass(item.media)"></i>
            </b-avatar>

            {{ item.name ? item.name : `Chat #${idx}`}}
          </b-list-group-item>
        </b-list-group>
        
      </b-col>
      <b-col>
        <Chat
          v-if="chat"
          :exercise="exercise"
          :chat="chat"
          :chat-entries="chatEntries"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";
// import SocketIO from '@/util/socketio';

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "ChatList",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      showChatForm: false,
      socketEventName: "",
      mainProps: {
        blank: true,
        width: 10,
        height: 10,
        class: "m1",
      },
      chat: null,
      chatEntries: [],
    };
  },
  computed: {},
  created: function() {},
  mounted: function() {
    const that = this;

    // that.listenExerciseList(that.exercise, "chat");

    // const chatEntrySocketName = `${that.exercise._id}-chat-entries`;
    // that.subscribe(chatEntrySocketName, (data) => {
    //   const index = _.findIndex(that.chatEntries, (o) => {
    //     return o._id == data._id;
    //   });

    //   if (index >= 0) {
    //     that.chatEntries.splice(index, 1);
    //   }

    //   that.chatEntries.push(data);
    // });

    that.find();

    that.socketIOSubscribe({
      name: `${that.exercise._id}-chat-list`,
    });

    that.socketIOSubscribe({
      name: `${that.exercise._id}-chat-entries`,
      callback: (data) => {
        const index = _.findIndex(that.chatEntries, (o) => {
          return o._id == data._id;
        });

        if (index >= 0) {
          // that.chatEntries.splice(index, 1);
          that.$set(that.chatEntries, index, data)
        } else {
          that.chatEntries.push(data);
        }
      },
    });
  },
  methods: {
    find: async function() {
      const that = this;
      const response = await that.$http.get(`/chat`, {
        params: {
          exercise: that.exercise._id,
          role: that.$store.getters['sim/getRoleID'],
        },
      });
      that.itemList = response.data;

      const chatEntriesResponse = await that.$http.get(`/chat-entries`, {
        params: {
          exercise: that.exercise._id,
        },
      });
      that.chatEntries = chatEntriesResponse.data;
    },
    getClass: function(media) {
      if (media == "landline") {
        return "fa-phone";
      } else if (media == "cell-phone") {
        return "fa-mobile";
      } else if (media == "text") {
        return "fa-sms";
      } else {
        return "fa-at";
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    // this.sockets.unsubscribe(this.socketEventName);
  },
};
</script>
