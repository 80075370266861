<template>
  <BaseSimWidget id="roleWidget">
    <template v-slot:left-notification>
      <b-badge variant="danger">1</b-badge>
      <b-badge variant="warning" class="ml-1">2</b-badge>
    </template>
    <template v-slot:right-notification>
      <b-badge variant="warning">10</b-badge>
    </template>
    <template v-slot:title>
      <i class="fas fa-users"></i>
      Roles
    </template>

    <b-tabs v-model="tabIndex" align="right" pills>
      <template #tabs-end v-if="!fullScreen">
        <!-- <b-nav-item>
          <i class="fas fa-chevron-down"></i>
        </b-nav-item> -->
        <b-nav-item>
          <i
            class="fas fa-expand-arrows-alt"
            @click.prevent="goFullScreen()"
          ></i>
        </b-nav-item>
      </template>

      <b-tab>
        <template #title>
          <i class="fas fa-list"></i>
        </template>

        <b-container fluid class="tw-my-3" v-if="canJoinAsExerciseController">
          <b-row>
            <b-col>
              <multiselect
                v-model="position"
                :options="positionList"
                size="lg"
              ></multiselect>
            </b-col>
            <b-col>
              <b-button
                :disabled="!position"
                @click="selectExerciseController()"
                block
                size="lg"
              >
                Select Controller Position
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <hr>
            </b-col>
          </b-row>
        </b-container>

        <RoleList
          :exercise="exercise"
          v-on:editinexercise="editInExercise($event)"
          v-on:selectrole="selectRole($event)"
          v-on:leaverole="leaveRole($event)"
        />
      </b-tab>
      <b-tab
        :disabled="
          !isExerciseController() && !hasPermission(widgets.role.slug)
        "
      >
        <template #title>
          <i class="fas fa-briefcase"></i>
        </template>

        <RoleLibrary
          :exercise="exercise"
          v-on:addtoexercise="addItemToExercise($event)"
        />
      </b-tab>
      <b-tab
        :disabled="
          !isExerciseController() && !hasPermission(widgets.role.slug)
        "
      >
        <template #title>
          <i class="fas fa-plus"></i>
        </template>

        <RoleForm
          :exercise="exercise"
          :copy="copy"
          :id="id"
          v-on:form="formFinished($event)"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <i class="fas fa-question"></i>
        </template>

        <p>Help will be here.</p>
      </b-tab>
    </b-tabs>
  </BaseSimWidget>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import * as _ from "lodash";

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "RoleWidget",
  mixins: [WidgetMixin],
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
    fullScreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      show: true,
      fullWidth: false,
      title: "Sample Page",
      addRole: false,
      newRole: false,
      id: null,
      copy: false,
      tabIndex: 0,
      position: "",
      positionList: [
        "Lead Controller",
        "MSEL Manager",
        "Simulation Cell Controller",
        "Ground Truth Advisor",
        "Venue Controller",
        "Observer/Media Area Controller",
        "Exercise Assembly Area Controller",
      ],
    };
  },
  computed: {
    ...mapState("sample", ["sample"]),
    canJoinAsExerciseController: function() {
      const hasRole = this.$store.state.user.user.systemRoles.indexOf('exercise-controller') >= 0;
      const hasNotJoined = this.$store.getters['sim/getRoleID'];

      return hasRole && !hasNotJoined;
    }
  },
  created: function () {},
  mounted: function () {},
  methods: {
    formFinished: function () {
      this.id = null;
      this.copy = false;
      this.tabIndex = 0;
    },
    addItemToExercise: function (item) {
      this.id = item._id;
      this.copy = true;
      this.tabIndex = 2;
    },
    editInExercise: function (item) {
      this.id = item._id;
      this.copy = false;
      this.tabIndex = 2;
    },
    selectRole: async function (item) {
      const that = this;
      const newItem = _.cloneDeep(item);

      if(!item.role) {
        item.role = item._id;
      }

      // Update the current role.
      item.actionTime = that.exercise.currentTime;
      await that.$http.patch(`${that.widgets.role.baseUrl}/${item._id}`, {
        actionTime: that.exercise.currentTime,
        role: item.role
      });

      // Clean the new role.
      delete newItem._id;
      delete newItem.actionTime;
      newItem.primaryUser = that.$store.state.user.user._id;
      newItem.sendTime = that.exercise.currentTime;
      newItem.role = item.role;

      const newRole = await that.$http.post(
        `${that.widgets.role.baseUrl}`,
        newItem
      ).then((response) => response.data);
      that.$store.commit("sim/setRole", newRole);
    },
    leaveRole: async function (item) {
      const that = this;
      const newItem = _.cloneDeep(item);

      // Update the current role.
      item.actionTime = that.exercise.currentTime;
      await that.$http.patch(`${that.widgets.role.baseUrl}/${item._id}`, {
        actionTime: that.exercise.currentTime,
      });

      // Clean the new role.
      delete newItem._id;
      delete newItem.actionTime;
      delete newItem.primaryUser;
      newItem.sendTime = that.exercise.currentTime;
      newItem.role = item.role;

      // If controller, don't create another role.
      if (!item.controller) {
        const newRole = await that.$http.post(
          `${that.widgets.role.baseUrl}`,
          newItem
        ).then((response) => response.data);
      }

      that.$store.commit("sim/setRole", {});
    },
    selectExerciseController: async function () {
      const that = this;

      const data = {
        exercise: that.exercise._id,
        user: that.$store.state.user.user._id,
        primaryUser: that.$store.state.user.user._id,
        controller: true,
        name: that.position,
        permissions: [],
        sendTime: that.exercise.currentTime
      };

      const role = await that.$http.post(`${that.widgets.role.baseUrl}`, data).then(
        (response) => response.data
      );

      await that.$http.patch(`${that.widgets.role.baseUrl}/${role._id}`, {role: role._id});
      role.role = role._id;

      that.$store.commit("sim/setRole", role);
    },
    addItemToExercise: function (item) {
      this.id = item._id;
      this.copy = true;
      this.tabIndex = 2;
    },
    goFullScreen() {
      this.$emit("setfullscreenmodalwidget", "RoleSimWidget");
    },
  },
};
</script>
