<template>
    <b-card class="my-2">

      <b-card-title>
        <b-img
            v-bind="{width: 20, height: 20}"
            :src="item.icon ? item.icon : '/simeoc/simeoc-map-icons/blue.png'"
        ></b-img>

        {{ item.name }}

        <small>
          <i class="fas fa-2x tw-cursor-pointer float-right tw-ml-2" :class="{'fa-eye': !preview, 'fa-eye-slash': preview}" @click="preview = !preview" title="Preview"></i>

          <ShowSendTimeBadge
            class="float-right"
            :exercise="exercise"
            :item="item"
          />
          <MapCategory class="float-right mr-1" :category="item.category" />
        </small>
      </b-card-title>

      <b-card-text>
        <hr />
      </b-card-text>

      <b-card-text v-html="item.description"></b-card-text>

      <b-card-text style="height: 450px" v-if="preview">
        <MapPreview :item="item" />
      </b-card-text>

      <!-- <b-card-text>
        <b-container fluid>
          <b-row>
            <b-col cols="auto">
              <b-button
                variant="outline-primary"
                @click="preview = !preview"
                block
                v-if="!preview"
              >
                Preview
              </b-button>
              <b-button
                variant="outline-primary"
                @click="preview = !preview"
                block
                v-if="preview"
              >
                Hide
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-card-text> -->
      
    </b-card>
</template>

<script>

import * as _ from "lodash";

export default {
  name: "MapListItem",
  mixins: [],
  components: {},
  props: {
    item: {
      type: Object,
      required: false,
    },
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
  },
  data: function() {
    return {
      preview: false,
    };
  },
  created() {
    this.mapRef = `ref-${Math.random()}`;
  }
};
</script>
