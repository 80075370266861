<template>
    <b-card tag="article" class="my-2">
      <b-card-title>
        {{ item.name }}
  
        <ShowSendTimeBadge
            class="float-right"
            :exercise="exercise"
            :item="item"
          />
      </b-card-title>
  
      <b-card-text>
        {{ item.description }}
      </b-card-text>
  
      <b-card-text>
        <b-badge
            class="mr-1"
            v-for="column in item.columns"
            :key="column"
          >
            {{ column }}
          </b-badge>
      </b-card-text>

  
    </b-card>
  </template>
  
  <script>
  import "ag-grid-community/dist/styles/ag-grid.css";
  import "ag-grid-community/dist/styles/ag-theme-alpine.css";
  // import "ag-grid-community/dist/styles/ag-theme-balham.css";
  import { AgGridVue } from "ag-grid-vue";
  
  import * as _ from "lodash";
  
  import SocketIOMixin from '@/util/socketio';
  export default {
    name: "Spreadsheet",
    mixins: [SocketIOMixin],
    components: {
      AgGridVue,
    },
    props: {
      item: {
        type: Object,
        required: false,
      },
      exercise: {
        type: Object,
        required: false,
        default: Object,
      }
    },
    data: function () {
      return {};
    },
    created() {}
  };
  </script>
  