<template>
    <b-card class="mb-2">
        <b-card-title>
            {{ item.resource }}
            <ShowSendTimeBadge
                class="float-right"
                :exercise="exercise"
                :item="item"
            />
        </b-card-title>

        <b-card-text>
            {{ item.description }}
        </b-card-text>

        <b-card-text>
            
            <b-badge class="py-2 px-3 tw-m-2">
                Available:
            </b-badge>
            <b-badge variant="light" class="py-2 px-3 tw-m-2">
                {{ item.quantity - item.usage }} / {{ item.quantity }} {{ item.units }}
            </b-badge>
            

            <b-badge
                v-if="item.returnable"
                variant="primary"
                class="float-right"
            >
                returnable
            </b-badge>
        </b-card-text>

    </b-card>
</template>

<script>

export default {
    name: "InventoryListItem",
    mixins: [],
    components: {},
    props: {
        item: {
            type: Object,
            required: false,
        }
    },
    data: function () {
        return {};
    },
    created() {}
};
</script>
