<template>
    <b-card class="my-2" :bg-variant="item.severe ? 'danger' : ''" :text-variant="item.severe ? 'white' : ''">
      <b-card-title>
  
        <ShowSendTimeBadge
            class="float-right"
            :exercise="exercise"
            :item="item"
          />
        
          <b-badge variant="info" class="float-right mr-2">
          {{ item.humidity }} <span>&percnt;</span> H
        </b-badge>
        <b-badge variant="secondary" class="float-right mr-2">
          {{ item.temperature }} <span>&deg;</span> ({{item.windChill}} <span>&deg;</span> WC)
        </b-badge>
        <b-badge variant="dark" class="float-right mr-2">
          {{ item.windSpeed }} mph {{item.windDirection}}
        </b-badge>
        <b-badge variant="primary" class="float-right mr-2">
          {{ item.forecast }}
        </b-badge>
      </b-card-title>
  
      <b-card-text class="tw-pt-2">
        <b-media>
        <template #aside>
          <b-img v-bind="{width: 75, height: 75}" :src="`simeoc/weather/${item.forecast.replace(' ', '')}.png`" fluid alt="Responsive image"></b-img>
        </template>
  
        <div>
          {{ item.forecast }}
          {{ item.description }}
        </div>
      </b-media>

      

      </b-card-text>
    </b-card>
  </template>
  
  <script>
  
  export default {
    name: "WeatherListItem",
    mixins: [],
    components: {},
    props: {
      item: {
        type: Object,
        required: false,
      },
      exercise: {
        type: Object,
        required: false,
      },
    },
    data: function() {
      return {
        
      };
    }
  };
  </script>
  