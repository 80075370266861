<template>
    <div>
        <b-form-group id="name-group" label="Chat Name:" label-for="name">
            <b-form-input
                id="name"
                v-model="form.name"
                required
                placeholder="Enter Chat Name"
            ></b-form-input>
        </b-form-group>
        <b-form-group id="role-group" label="Roles:" label-for="roles">
            <multiselect
                v-model="form.selectedRoles"
                :multiple="true"
                :options="$store.getters['sim/getTraineeRolesExceptMe']"
                track-by="_id"
                label="name"
            ></multiselect>
        </b-form-group>

        <b-form-group id="media-group" label="Media:" label-for="media">
            <multiselect
                v-model="form.media"
                :multiple="false"
                :options="mediaTypes"
            ></multiselect>
        </b-form-group>

        <br />

        <b-btn variant="primary" block @click="create()"> Create </b-btn>
    </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import * as _ from 'lodash';

import SocketIOMixin from '@/util/socketio';
export default {
    name: "ChatForm",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            form: {
                media: "",
                roles: [],
            },
            roles: [],
            mediaTypes: ["landline", "cell-phone", "text", "email"],
        };
    },
    computed: {},
    created: function () {
        this.init();
    },
    mounted: function () {},
    methods: {
        init: async function () {
            // const that = this;
            // let url = `/role`;

            // if (that.exercise._id) {
            //     url = `/role?exercise=${that.exercise._id}`;
            // }

            // that.roles = await that.$http.get(url).then((response) => {
            //     return response.data;
            // });
        },
        create: async function () {
            const that = this;
            if (!that.form.selectedRoles.length) {
                return;
            }

            that.form.exercise = that.exercise._id;
            that.form.sendTime = that.exercise.currentTime;
            that.form.role = that.$store.getters['sim/getRoleID'];

            that.form.roles = [that.form.role];
            _.each(that.form.selectedRoles, (role) => {
                that.form.roles.push(that.$store.getters['sim/getRoleIDFromID'](role._id));
            });

            const response = await that.$http.post("/chat", that.form);

            that.form = {
                media: "",
                roles: [],
            };

            that.$emit("chatcreated", response.data._id);
        },
    },
};
</script>
