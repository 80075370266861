<template>
    <b-card class="my-2" v-if="isItemVisible(exercise, $store.state.sim.role, item)">
      <b-card-title>
        {{ item.title }}

        <ShowSendTimeBadge
          class="float-right"
          :exercise="exercise"
          :item="item"
        />
      </b-card-title>

      <b-card-text>
        {{ item.message }}
      </b-card-text>

      <b-card-text v-if="item.role">
        <span
          class="badge badge-primary mr-1"
        >
          <RoleInfo :roleId="item.role" />
        </span>
      </b-card-text>

      <b-card-text v-if="item.roles && item.roles.length">
        <span
          class="badge badge-secondary mr-1"
          v-for="role in item.roles"
          :key="role"
        >
          <RoleInfo :roleId="role" />
        </span>
      </b-card-text>

    </b-card>
</template>

<script>

export default {
  name: "InjectListItem",
  mixins: [],
  components: {},
  props: {
    item: {
      type: Object,
      required: true
    },
    exercise: {
      type: Object,
      required: false,
    },
  },
  data: function() {
    return {};
  },
};
</script>
