<template>
    <b-card
      :img-src="item.imageUrl"
      img-alt="Image"
      img-top
      tag="article"
      class="my-2"
    >
      <b-card-title>
        {{ item.title }}
  
        <ShowSendTimeBadge
            class="float-right"
            :exercise="exercise"
            :item="item"
          />
      </b-card-title>
  
      <b-card-text>
        <ShowMedia
          v-if="item.mediaLibrary"
          :mediaLibraryId="item.mediaLibrary"
          :thumbnail="false"
        />
      </b-card-text>
      <hr />
  
      <b-card-text>
        <blockquote class="blockquote" v-html="item.message"></blockquote>
      </b-card-text>
  
      <b-card-text>
        <blockquote class="blockquote" v-html="item.description"></blockquote>
      </b-card-text>
  
      <b-card-text>
        <b-badge class="mx-1" v-for="tag in item.tags" :key="tag">
          {{ tag }}
        </b-badge>
      </b-card-text>
    </b-card>
  </template>
  
  <script>
  export default {
    name: "BroadcastListItem",
    mixins: [],
    components: {},
    props: {
      item: {
        type: Object,
        required: false,
      }
    },
    data: function() {
      return {
        
      };
    }
  };
  </script>
  