<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col" style="height: 500px;">
        <!-- <v-map
          :ref="ref"
          @ready="doSomethingOnReady()"
          :zoom="initialZoom"
          :center="initialLocation"
          class="tw-h-full"
          :options="{scrollWheelZoom: false}"
        >
          <v-tilelayer
            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
          ></v-tilelayer>

          <v-marker v-if="exercise.latitude" :lat-lng="[exercise.latitude, exercise.longitude]">
            <v-tooltip>Center</v-tooltip>
          </v-marker>

          <v-marker
            v-for="item in sortedItemList2"
            :key="item._id"
            :lat-lng="[item.latitude, item.longitude]"
          >
            <v-tooltip>{{item.name}}</v-tooltip>
          </v-marker>
        </v-map> -->

        <div ref="mainDisasterMapContainer" class="map-container" style="width: 100%; height: 100%;"></div>
      </div>
    </div>
    <div class="row">
      <div class="col tw-text-right tw-mt-1">
        <b-button
            class="tw-mr-1"
            title="Zoom to Bounds"
            variant="outline-primary"
            @click="fitBounds()"
        >
            <i class="fas fa-expand"></i>
        </b-button>
        <b-button
            title="Re-Center Map"
            variant="outline-secondary"
            @click="reCenterMap()"
        >
            <i class="fas fa-bullseye"></i>
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/util/http-common.js";
import HandleErrors from "@/util/handle-errors";

import mapboxgl from 'mapbox-gl';

import * as _ from "lodash";

import { LMap, LTileLayer, LMarker, LCircle, LRectangle, LTooltip } from "vue2-leaflet";
import { Icon, Marker, LayerGroup, Polyline, FeatureGroup, Rectangle } from "leaflet";

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

import SocketIOMixin from '@/util/socketio';
export default {
  mixins: [SocketIOMixin],
  components: {
    "v-map": LMap,
    "v-tilelayer": LTileLayer,
    "v-marker": LMarker,
    "v-circle": LCircle,
    "v-rectangle": LRectangle,
    "v-tooltip": LTooltip
  },
  props: {
        exercise: {
            type: Object,
            required: true
        },
        fullScreen: {
          type: Boolean,
          default: false
        },
        zoomLocation: {
          type: Object,
          default: null
        }
    },

    watch: {
        zoomLocation: function (val) {
            if (val) {
                this.zoomToLocation(val);
            }
        }
    },
  data() {
    return {
        socketEventName: '',
        items: [],
      editorOption: {
        modules: {},
        placeholder: "Enter exercise description",
      },
      form: {},
      initialLocation: [39.833333, -97.416667],
      initialZoom: 10,
      bounds: [
        [47.9, -2],
        [47.0, -0.5],
      ],
      icon: new Icon({
        iconUrl: "https://leafletjs.com/examples/custom-icons/leaf-orange.png",
      }),
      mapStyleObject: {'height': '1000px'},
      ref: 'lskdjflsd',
      map: null,
      mapOptions: {
        initialZoom: 10,
        initialLocation: [39.833333, -97.416667]
      },
    };
  },
  computed: {
      sortedItemList2() {
        const that = this;
        return _.filter(that.sortedItemList, (o) => {
          return that.isItemVisible(that.exercise, that.$store.state.sim.role, o);
        });
      },
  },
  mounted() {
    const that = this;
    
    that.socketIOSubscribe({
      name: `${that.exercise._id}-map-view-list`,
      callback: (data) => {
        const index = _.findIndex(that.itemList, (o) => {
          return o._id == data._id;
        });

        if (index >= 0) {
          const oldItem = that.itemList[index];
          that.$set(that.itemList, index, data);
          that.itemList[index].marker = oldItem.marker;
        } else {
          that.itemList.push(data);
        }

        that.resetMarkers();
      },
    });

    if(that.fullScreen) {
      that.mapStyleObject['height'] = '1000px';
    } else {
      that.mapStyleObject['height'] = '500px';
    }

    that.mapOptions.initialLocation = [that.exercise.latitude, that.exercise.longitude];
    const map = new mapboxgl.Map({
        container: that.$refs.mainDisasterMapContainer,
        style: "mapbox://styles/mapbox/streets-v12", // Replace with your preferred map style
        center: [that.exercise.longitude ? that.exercise.longitude : that.mapOptions.initialLocation[1], that.exercise.latitude ? that.exercise.latitude : that.mapOptions.initialLocation[0]],
        zoom: that.exercise.zoom ? Math.ceil(that.exercise.zoom) : 10,
        boxZoom: true,
        cooperativeGestures: true,
    });

    // Add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl());

    that.map = map;

    that.find();
    // setTimeout(() => {
    //   that.zoomToLocation({latitude: 1, longitude: 1});
    // }, 3000);
  },
  created() {
    this.ref = this.ref + Math.random();
    
    // Set the initial zoom and location.
    if(this.exercise.latitude) {
      this.initialLocation = [this.exercise.latitude, this.exercise.longitude];
      this.initialZoom = this.exercise.zoom;
    }
  },
  methods: {
    async find() {
        const that = this;

        that.itemList = await that.$http.get(`/disaster-map?exercise=${that.exercise._id}`).then((response) => response.data);

        that.resetMarkers();
    },
    resetMarkers() {
      const that = this;
      _.each(that.itemList, (item) => {
        const isVisible = that.isItemVisible(that.exercise, that.$store.state.sim.role, item);
        // If item can be shown and no marker, then add it.
        // If item is to be hidden and has marker, then remove it.
        if(isVisible && !item.marker && item.latitude && item.longitude) {
          item.marker = that.addMarker(item);
        } else if(!isVisible && item.marker) {
          item.marker.remove();
          item.marker = null;
        }
      });
    },
    addMarker(item) {
        const that = this;

        const el = document.createElement('div');
        el.className = 'marker';

        if(item.icon) {
            el.style.backgroundImage = `url(${location.origin}/${item.icon})`;
        } else {
            el.style.backgroundImage = `url(${location.origin}/simeoc/simeoc-map-icons/black.png)`;
        }

        el.style.width = `50px`;
        el.style.height = `50px`;
        el.style.backgroundSize = '100%';
        el.style.backgroundRepeat = 'no-repeat';

        return new mapboxgl.Marker(el).setLngLat([item.longitude, item.latitude]).addTo(that.map);
    },
    doSomethingOnReady: function () {
      const that = this;

      setTimeout(() => {
        that.fitBounds();
        const rectangle = new Rectangle(that.$refs[that.ref].mapObject.getBounds(), {color: "#ff7800", weight: 0.1});
        rectangle.addTo(that.$refs[that.ref].mapObject)
      }, 3000);
    },
    reCenterMap() {
      const that = this;
      that.map.setZoom(that.exercise.zoom);
      that.map.setCenter([that.exercise.longitude, that.exercise.latitude]);
    },
    fitBounds() {
      const that = this;

      const items = _.map(that.sortedItemList2, (it) => {
        return [it.longitude, it.latitude];
      });

      if(items.length > 1) {
        that.map.fitBounds(items);
      } else {
        that.reCenterMap();
      }
    },
    zoomToLocation(item) {
      const that = this;
      that.map.flyTo({
        center: [item.longitude, item.latitude],
        zoom: 14,
        essential: true // this animation is considered essential with respect to prefers-reduced-motion
      });
    }
  },
    beforeRouteLeave(to, from, next) {
        // this.sockets.unsubscribe(this.socketEventName);
        // this.sockets.unsubscribe(`${this.exercise._id}-remove-map-list`);
    },
};
</script>
<style>
</style>
