<template>
  <div class="mt-2">

    <Role
      v-for="item in myRoles"
      :key="item._id"
      :input="item"
      :exercise="exercise"
      v-on:removefromexercise="onRemoveFromExercise"
      v-on:editinexercise="onEditInExercise"
      v-on:selectrole="$emit('selectrole', $event)"
      v-on:leaverole="$emit('leaverole', $event)"
    />

    <Role
      v-for="item in notMyRoles"
      :key="item._id"
      :input="item"
      :exercise="exercise"
      v-on:removefromexercise="$emit('removefromexercise', $event)"
      v-on:editinexercise="$emit('editinexercise', $event)"
      v-on:selectrole="$emit('selectrole', $event)"
      v-on:leaverole="$emit('leaverole', $event)"
    />
  </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";
import * as _ from "lodash";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "RoleList",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      socketEventName: "",
    };
  },
  computed: {
    myRoles() {
      const that = this;
      return _.filter(that.itemList, function(o) {
        return o.primaryUser == that.$store.state.user.user._id;
      });
    },
    notMyRoles() {
      const that = this;
      return _.filter(that.itemList, function(o) {
        return o.primaryUser != that.$store.state.user.user._id;
      });
    }
  },
  created: function() {

  },
  mounted: async function() {
    const that = this;
    await that.find();

    const options = {
      debug: true,
      name: `${that.exercise._id}-role-list`,
      lastCallback: (data) => {
        console.log("---------------------");
        console.log(data);
        that.$store.commit("sim/updateRoles", [data]);

        console.log(data.simcell);

        // If the role being updated belongs to current role. We only check data.role because role is already a child at this point.
        if(data.role && data.role == that.$store.getters['sim/getRoleID']) {
          that.$store.commit("sim/setRole", data);
        }
      }
    }

    that.socketIOSubscribe(options);
  },
  methods: {
    find: async function() {
      const that = this;
      that.$store.commit("sim/setRole", {});

      const itemList = await that.$http.get(`/role`, {params: {exercise: that.exercise._id}}).then((response) => response.data);
      // const itemList = await that.$http.get(`/role`).then((response) => response.data);

      // Set the role for the current exercise if any.
      _.each(itemList, (it) => {
        if (it.primaryUser == that.$store.state.user.user._id && it.actionTime != 0 && !_.isNumber(it.actionTime)) {
          that.$notify({
            message: `You have joined the exercise as '${it.name}'`,
            timeout: 10000,
            type: "success",
          });

          that.$store.commit("sim/setRole", it);
          // that.$emit('leaverole', it);
        }
      });

      that.$store.commit("sim/updateRoles", itemList);

      that.itemList = itemList;
    },
    onEditInExercise: function(item) {
      const that = this;
      that.$emit('editinexercise', item);
      setTimeout(() => {
        that.find();
      }, 1000);
    },
    onRemoveFromExercise: function(item) {
      const that = this;
      that.$emit('removefromexercise', item);
      setTimeout(() => {
        that.find();
      }, 1000);
    }
  },
  beforeRouteLeave(to, from, next) {},
};
</script>
